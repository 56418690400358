<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <!-- Title -->
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">Restaurantes</h2>
        </v-col>
        <!-- Title -->

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <!-- Restaurants list -->
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <!-- FILTROS -->
              <v-row>
                <!-- Filtro por NIF -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    label="NIF"
                    prepend-icon="mdi-account-card-details"
                    v-model="filter.nif"
                    @keyup.enter="search(1)"
                  ></v-text-field>
                </v-col>

                <!-- Filtro por nombre -->
                <v-col cols="12" sm="6" md="4" class="py-0">
                  <v-text-field
                    label="Display name"
                    prepend-icon="mdi-store"
                    v-model="filter.name"
                    @keyup.enter="search(1)"
                  ></v-text-field>
                </v-col>

                <!-- Filtro por email -->
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    label="Número de teléfono"
                    prepend-icon="mdi-phone"
                    v-model="filter.phone"
                    @keyup.enter="search(1)"
                  ></v-text-field>
                </v-col>

                <!-- Buttons -->
                <v-col cols="12" class="text-right">
                  <v-btn @click="reset()" class="ma-2">Limpiar filtros</v-btn>
                  <v-btn color="primary" @click="search(1)" class="ma-2"
                    >Buscar</v-btn
                  >
                </v-col>
              </v-row>
              <!-- FILTROS -->

              <v-row align="center" no-gutters class="px-2">
                <v-col class="shrink align-self-start">
                  <v-card-text class="order-history"></v-card-text>
                </v-col>

                <v-col>
                  <v-card flat tile class="order-history">
                    <v-row align="center" no-gutters>
                      <v-col cols="3">
                        <v-card-text class="subtitle-1 text-truncate px-1"
                          >NIF</v-card-text
                        >
                      </v-col>

                      <v-col cols="6">
                        <v-card-text class="subtitle-1 text-truncate px-1"
                          >Nombre Restaurante</v-card-text
                        >
                      </v-col>

                      <v-col class="grow d-none d-md-flex">
                        <v-card-text class="subtitle-1 text-truncate px-1"
                          >Telefono</v-card-text
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" class="px-2">
                  <v-alert
                    :value="restaurants.length == 0"
                    border="left"
                    colored-border
                    type="info"
                    elevation="2"
                    class="mt-4"
                    >No hemos encontrado ningún restaurante con estos criterios
                    de búsqueda.</v-alert
                  >

                  <easy-serve-restaurant
                    v-for="(restaurant, index) in restaurants"
                    :key="'restaurant_' + restaurant.uuid"
                    :restaurant="restaurant"
                    :last="restaurants.length - 1 == index"
                    @click="navigateToRestaurant(restaurant)"
                  ></easy-serve-restaurant>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Restaurants list -->

        <!-- Start pagination -->
        <v-col cols="12">
          <div class="text-right my-4">
            <v-pagination
              v-model="page"
              :length="last_page"
              :total-visible="7"
              @input="search"
            ></v-pagination>
          </div>
        </v-col>
        <!-- End pagination -->
      </v-row>
    </v-container>

    <!-- Create fab button -->
    <v-fab-transition>
      <v-btn
        color="red"
        fab
        dark
        large
        bottom
        right
        ripple
        fixed
        @click="create"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- Create fab button -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../../mixins/errors';
import { mapGetters } from 'vuex';

import EximyrLoading from '../../components/EximyrLoading';
import EasyServeRestaurant from '../components/EasyServeRestaurant';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EasyServeRestaurant,
  },

  data: () => ({
    page: null,
    dialog: false,

    filter: {
      nif: null,
      name: null,
      phone: null,
    },
  }),

  computed: {
    ...mapGetters('restaurantsAdministration', {
      restaurants: 'restaurants',
      last_page: 'last_page',
      loading: 'loading',
    }),
  },

  watch: {
    $route() {
      this.load();
    },
  },

  created() {
    // Set the current page if there is one set
    this.page = this.currentPage();

    // Set all filter variables from URL
    // Asign filter variables
    this.filter.nif = this.currentFilterNif();
    this.filter.name = this.currentFilterName();
    this.filter.phone = this.currentFilterPhone();

    // Now load all employees
    this.load();
  },

  methods: {
    currentPage() {
      if (isNaN(this.$route.query.page)) {
        return 1;
      } else {
        return parseInt(this.$route.query.page);
      }
    },

    load() {
      // Create the payload
      const payload = {
        restaurant: this.$route.params.restaurant,
        filter: this.filter,
        page: this.page,
      };

      this.$store.dispatch('restaurantsAdministration/load', payload).then();
    },

    reset() {
      this.filter.nif = null;
      this.filter.name = null;
      this.filter.phone = null;
    },

    search(page = null) {
      // Filter the restaurants
      this.$router
        .push({
          name: 'administration.restaurants',
          query: this.query(page),
        })
        // We need to ignore the error (same route)
        .catch(() => {});
    },

    query(page = null) {
      if (page) this.page = page;

      let query = {
        page: this.page,
      };

      // Check all filters
      if (this.filter.name != '') {
        query.name = this.filter.name;
      }

      if (this.filter.nif.trim() != '') {
        query.nif = this.filter.nif;
      }

      if (this.filter.phone.trim() != '') {
        query.phone = this.filter.phone;
      }

      return query;
    },

    create() {
      // TODO: Run the create restaurant wizzard
      this.$router.push({
        name: 'administration.restaurants.create',
      });
    },

    navigateToRestaurant(restaurant) {
      location = '/' + restaurant.uuid + '/contact-information';
      // this.$router.push({
      //   name: 'contact-information',
      //   params: { restaurant: restaurant.uuid }
      // });
    },

    // Filter methods
    currentFilterNif() {
      const nif = this.$route.query.nif;

      if (nif === undefined) {
        return '';
      }

      return nif.toString();
    },

    currentFilterName() {
      const name = this.$route.query.name;

      if (name === undefined) {
        return '';
      }

      return name.toString();
    },

    currentFilterPhone() {
      const phone = this.$route.query.phone;

      if (phone === undefined) {
        return '';
      }

      return phone.toString();
    },
  },
};
</script>

<style>
.order-history > .layout > .flex > .v-card__text {
  padding: 2px 8px;
}
.order-history.v-card__text {
  padding: 0px 8px;
}

.order-history.v-card.v-card--flat.v-sheet.theme--light {
  border-bottom: 1px solid #ccc;
}
</style>
