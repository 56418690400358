<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <!-- Title -->
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">Packs Mesas</h2>
        </v-col>
        <!-- Title -->

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <!-- Restaurants list -->
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <!-- FILTROS -->
              <v-row>
                <!-- Filtro por created_at -->
                <v-col cols="12" sm="6" class="py-0">
                  <v-menu
                    v-model="activeCreatedAt"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="filter.created_at"
                        label="Fecha creación"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es-es"
                      v-model="filter.created_at"
                      @input="activeCreatedAt = false"
                      show-current
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Filtro por numero de mesas desde -->
                <v-col cols="12" sm="3" class="py-0">
                  <v-text-field
                    label="Nr de mesas desde"
                    prepend-icon="mdi-table-chair"
                    v-model="filter.from_tables"
                    type="number"
                    @keyup.enter="search(1)"
                  ></v-text-field>
                </v-col>

                <!-- Filtro por numero de mesas hasta -->
                <v-col cols="12" sm="3" class="py-0">
                  <v-text-field
                    label="Nr de mesas hasta"
                    prepend-icon="mdi-table-chair"
                    v-model="filter.to_tables"
                    type="number"
                    @keyup.enter="search(1)"
                  ></v-text-field>
                </v-col>

                <!-- Filtro por estado del impresión -->
                <v-col cols="12" md="6" class="py-0 align-self-end">
                  <v-select
                    v-model="filter.printed"
                    :items="printedStatus"
                    label="Imprimidos"
                    item-value="id"
                    item-text="value"
                    prepend-icon="mdi-printer-check"
                    deletable-chips
                  ></v-select>
                </v-col>

                <!-- Filtro por estado del impresión -->
                <v-col cols="12" md="6" class="py-0 align-self-end">
                  <v-select
                    v-model="filter.assigned"
                    :items="assignedStatus"
                    label="Asociados a Restaurante"
                    item-value="id"
                    item-text="value"
                    prepend-icon="mdi-arrow-collapse"
                    deletable-chips
                  ></v-select>
                </v-col>

                <!-- Buttons -->
                <v-col cols="12" class="text-right">
                  <v-btn @click="reset()" class="ma-2">Limpiar filtros</v-btn>
                  <v-btn color="primary" @click="search(1)" class="ma-2"
                    >Buscar</v-btn
                  >
                </v-col>
              </v-row>
              <!-- FILTROS -->

              <v-row align="center" no-gutters class="px-2">
                <v-col>
                  <v-card flat tile class="order-history">
                    <v-row align="center" no-gutters>
                      <v-col cols="6" sm="4" md="2">
                        <v-card-text class="subtitle-1 text-truncate px-1"
                          >Alias</v-card-text
                        >
                      </v-col>

                      <v-col cols="6" sm="4" md="3">
                        <v-card-text class="subtitle-1 text-truncate px-1"
                          >Fecha generación</v-card-text
                        >
                      </v-col>

                      <v-col md="3" class="d-none d-md-flex">
                        <v-card-text class="subtitle-1 text-center px-1"
                          >Número de mesas</v-card-text
                        >
                      </v-col>

                      <v-col cols="2" class="d-none d-sm-flex">
                        <v-card-text class="subtitle-1 text-center px-1"
                          >Asignado</v-card-text
                        >
                      </v-col>

                      <v-col cols="2" class="d-none d-sm-flex">
                        <v-card-text class="subtitle-1 text-center px-1"
                          >Imprimido</v-card-text
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" class="px-2">
                  <v-alert
                    :value="packs.length == 0"
                    border="left"
                    colored-border
                    type="info"
                    elevation="2"
                    class="mt-4"
                    >No hemos encontrado ningún pack de mesas con estos
                    criterios de búsqueda.</v-alert
                  >

                  <easy-serve-pack
                    v-for="(pack, index) in packs"
                    :key="'pack_' + pack.uuid"
                    :pack="pack"
                    :last="packs.length - 1 == index"
                  ></easy-serve-pack>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Restaurants list -->

        <!-- Start pagination -->
        <v-col cols="12">
          <div class="text-right my-4">
            <v-pagination
              v-model="page"
              :length="last_page"
              :total-visible="7"
              @input="search"
            ></v-pagination>
          </div>
        </v-col>
        <!-- End pagination -->
      </v-row>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../../mixins/errors';
import { mapGetters } from 'vuex';

import EximyrLoading from '../../components/EximyrLoading';
import EasyServePack from '../components/EasyServePack';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
    EasyServePack,
  },

  data: () => ({
    page: null,

    activeCreatedAt: false,

    printedStatus: [
      {
        id: null,
        value: 'Cualquiera',
      },
      {
        id: 0,
        value: 'No imprimidos',
      },
      {
        id: 1,
        value: 'Imprimidos',
      },
    ],

    assignedStatus: [
      {
        id: null,
        value: 'Cualquiera',
      },
      {
        id: 0,
        value: 'No asociados',
      },
      {
        id: 1,
        value: 'Asociados',
      },
    ],

    filter: {
      created_at: null,
      from_tables: null,
      to_tables: null,
      printed: null,
      assigned: null,
    },
  }),

  computed: {
    ...mapGetters('packs', {
      packs: 'packs',
      last_page: 'last_page',
      loading: 'loading',
    }),
  },

  watch: {
    $route() {
      this.load();
    },
  },

  created() {
    // Set the current page if there is one set
    this.page = this.currentPage();

    // Set all filter variables from URL
    // Asign filter variables
    this.filter.created_at = this.currentFilterCreatedAt();
    this.filter.from_tables = this.currentFilterFromTables();
    this.filter.to_tables = this.currentFilterToTables();
    this.filter.assigned = this.currentFilterAssigned();
    this.filter.printed = this.currentFilterPrinted();

    // Now load all packs
    this.load();
  },

  methods: {
    currentPage() {
      if (isNaN(this.$route.query.page)) {
        return 1;
      } else {
        return parseInt(this.$route.query.page);
      }
    },

    load() {
      // Create the payload
      const payload = {
        filter: this.filter,
        page: this.page,
      };

      this.$store.dispatch('packs/load', payload).then();
    },

    reset() {
      this.filter.created_at = null;
      this.filter.from_tables = null;
      this.filter.to_tables = null;
      this.filter.printed = null;
      this.filter.assigned = null;
    },

    search(page = null) {
      // Filter the restaurants
      this.$router
        .push({
          name: 'administration.packs',
          query: this.query(page),
        })
        // We need to ignore the error (same route)
        .catch(() => {});
    },

    query(page = null) {
      if (page) this.page = page;

      let query = {
        page: this.page,
      };

      // Check all filters
      if (this.filter.created_at) {
        query.created_at = this.filter.created_at;
      }

      if (this.filter.from_tables) {
        query.from_tables = this.filter.from_tables;
      }

      if (this.filter.to_tables) {
        query.to_tables = this.filter.to_tables;
      }

      if (this.filter.printed != null) {
        query.printed = this.filter.printed;
      }

      if (this.filter.assigned != null) {
        query.assigned = this.filter.assigned;
      }

      return query;
    },

    // Filter methods
    currentFilterCreatedAt() {
      const created_at = this.$route.query.created_at;

      if (created_at === undefined) {
        return '';
      }

      return created_at.toString();
    },

    currentFilterFromTables() {
      const from_tables = this.$route.query.from_tables;

      if (from_tables === undefined) {
        return '';
      }

      return from_tables.toString();
    },

    currentFilterToTables() {
      const to_tables = this.$route.query.to_tables;

      if (to_tables === undefined) {
        return '';
      }

      return to_tables.toString();
    },

    currentFilterPrinted() {
      const printed = this.$route.query.printed;

      if (printed === undefined) {
        return null;
      }

      return isNaN(printed) ? null : parseInt(printed);
    },

    currentFilterAssigned() {
      const assigned = this.$route.query.assigned;

      if (assigned === undefined) {
        return null;
      }

      return isNaN(assigned) ? null : parseInt(assigned);
    },
  },
};
</script>

<style></style>
