<template>
  <v-main>
    <v-container fluid>
      <v-row align="center">
        <eximyr-breadcrumb :breadcrumbs="breadcrumbs"></eximyr-breadcrumb>

        <v-col cols="12">
          <h2 class="display-1 font-weight-light pl-5">
            Crear nuevo restaurante
          </h2>
        </v-col>

        <!-- Errors and alerts -->
        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="flex-row-reverse">
        <v-col cols="12">
          <v-card>
            <v-form ref="form">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3 class="headline">Información de contacto</h3>
                  </v-col>
                  <!-- Restaurant's name -->
                  <v-col cols="12" md="6" xl="3">
                    <v-text-field
                      v-model="name"
                      label="Nombre restaurante"
                      :rules="rules.name"
                      counter="150"
                    ></v-text-field>
                  </v-col>

                  <!-- Restaurant's phone number -->
                  <v-col cols="12" md="6" xl="3">
                    <v-text-field
                      label="Número de teléfono"
                      v-model="phone_number"
                      :rules="rules.phone_number"
                    ></v-text-field>
                  </v-col>

                  <!-- Restaurant's nif -->
                  <v-col cols="12" md="6" xl="3">
                    <v-text-field
                      label="NIF"
                      v-model="nif"
                      :rules="rules.nif"
                    ></v-text-field>
                  </v-col>

                  <!-- Restaurant's email -->
                  <v-col cols="12" md="6" xl="3">
                    <v-text-field
                      label="Correo Electrónico"
                      v-model="email"
                      :rules="rules.email"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <h3 class="headline">Dirección</h3>
                  </v-col>

                  <!-- Restaurant's address -->
                  <v-col cols="12" md="4">
                    <v-combobox
                      v-model="road_type"
                      :items="roadTypes"
                      label="Selecciona un tipo de vía"
                      item-value="id"
                      item-text="road_key"
                      :loading="loadingTypes"
                      :rules="rules.road_type"
                      @focus="loadTypes"
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" md="8">
                    <v-text-field
                      label="Nombre de vía"
                      v-model="street"
                      counter="250"
                      :rules="rules.street"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Número"
                      v-model="number"
                      :rules="rules.number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Piso"
                      v-model="floor"
                      :rules="rules.floor"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Portal"
                      v-model="portal"
                      counter="20"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Puerta"
                      v-model="doorway"
                      counter="20"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Otros datos"
                      v-model="other_data"
                      counter="200"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Código postal"
                      v-model="postal_code"
                      :rules="rules.postal_code"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Provincia"
                      v-model="municipality"
                      counter="100"
                      :rules="rules.municipality"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Ciudad"
                      v-model="city"
                      counter="100"
                      :rules="rules.city"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="País"
                      v-model="country"
                      counter="100"
                      :rules="rules.country"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <h3 class="headline">Configuración</h3>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="sound_on_order"
                      label="Aviso sonoro al recibir un pedido"
                    ></v-checkbox>

                    <v-checkbox
                      class="mt-0"
                      v-model="sound_on_scan"
                      label="Aviso sonoro cuando un cliente escanea un código"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Tiempo sesión clientes (en segundos)"
                      v-model="client_expiration_time"
                      :rules="rules.client_expiration_time"
                      prepend-icon="mdi-timelapse"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-combobox
                      name="languages"
                      v-model="associated_languages"
                      :items="languages"
                      label="Idiomas disponibles"
                      item-value="id"
                      item-text="language"
                      prepend-icon="mdi-translate"
                      multiple
                      chips
                      deletable-chips
                      small-chips
                      :loading="loadingLanguages"
                      :rules="rules.languages"
                      @focus="loadLanguages"
                      @change="checkDefaultLanguage"
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      v-model="default_language"
                      :items="associated_languages"
                      prepend-icon="mdi-translate"
                      menu-props="auto"
                      label="Idioma por defecto"
                      item-value="id"
                      item-text="language"
                      :rules="rules.default_language"
                    ></v-select>
                  </v-col>

                  <!-- Buttons -->
                  <v-col cols="12" class="text-right">
                    <v-btn color="primary" @click.stop="save" class="ma-2"
                      >Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import goTo from 'vuetify/lib/services/goto';

import { required } from '@/validators/required';
import { length } from '@/validators/length';
import { dni } from '@/validators/dni';
import { numeric } from '@/validators/numeric';
import { email } from '@/validators/email';

// Language loader mixin
import errors from '../../mixins/errors';

import EximyrBreadcrumb from '../../components/EximyrBreadcrumb';
import EximyrLoading from '../../components/EximyrLoading';

export default {
  components: {
    EximyrBreadcrumb,
    EximyrLoading,
  },

  mixins: [errors],

  data: () => ({
    loading: false,

    restaurant_uuid: '',

    uuid: '',
    name: '',
    nif: '',
    phone_number: '',
    email: '',

    roadTypes: [],
    road_type: null,
    loadingTypes: false,

    street: '',
    number: '',
    floor: '',
    portal: '',
    doorway: '',
    other_data: '',
    postal_code: '',
    municipality: '',
    city: '',
    country: 'España',

    languagesLoaded: false,
    languages: [],
    default_language: null,
    associated_languages: [],

    sound_on_order: true,
    sound_on_scan: true,
    client_expiration_time: 14400,

    loadingLanguages: false,

    backend_restaurants: {
      id: null,
      name: null,
      nif: null,
      phone_number: null,
    },

    backend_errors: {
      name: [],
      nif: [],
      phone_number: [],
    },
  }),

  computed: {
    breadcrumbs() {
      const breadcrumbs = [
        {
          text: 'Restaurantes',
          disabled: false,
          exact: true,
          to: { name: 'administration.restaurants' },
        },
        {
          text: 'Crear nuevo restaurante',
          disabled: true,
          exact: false,
        },
      ];

      return breadcrumbs;
    },

    // Validation errors
    rules() {
      return {
        name: [required(this), length(this, 150)],
        nif: [required(this), dni(this)],
        email: [required(this), email(this)],
        phone_number: [required(this), numeric(this)],

        number: [required(this), numeric(this)],
        floor: [required(this)],
        street: [required(this)],
        postal_code: [required(this)],
        road_type: [required(this)],
        city: [required(this)],
        municipality: [required(this)],
        country: [required(this)],

        client_expiration_time: [required(this), numeric(this)],
        languages: [required(this)],
        default_language: [required(this)],
      };
    },
  },

  methods: {
    cancel() {
      this.$router.push({
        name: 'administration.restaurants',
        query: {
          page: this.create ? 1 : this.current_page,
        },
      });
    },

    save() {
      // Revalidate the form
      if (false == this.$refs.form.validate()) {
        goTo(0);
        return;
      }

      const payload = {
        name: this.name,
        phone_number: this.phone_number,
        nif: this.nif,
        email: this.email,

        // Create languages array
        languages: this.associated_languages.map(l => l.id),

        address: {
          road_type_id: this.road_type.id,
          street: this.street,
          number: this.number,
          postal_code: this.postal_code,
          floor: this.floor,
          portal: this.portal,
          doorway: this.doorway,
          other_data: this.other_data,
          municipality: this.municipality,
          city: this.city,
          country: this.country ? this.country : 'España',
        },

        config: {
          client_expiration_time: this.client_expiration_time,
          locale: this.getConfiguredLocale(),
          sound_on_order: this.sound_on_order,
          sound_on_scan: this.sound_on_scan,
        },
      };

      this.loading = true;

      this.$store
        .dispatch('restaurantsAdministration/create', payload)
        .then(() => {
          this.$router.push({
            name: 'administration.restaurants',
            query: {
              page: this.create ? 1 : this.current_page,
            },
          });
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
          goTo(0);
        });
    },

    getConfiguredLocale() {
      const language = this.associated_languages.find(
        l => l.id == this.default_language
      );

      return language ? language.code : '';
    },

    checkDefaultLanguage() {
      /**
       * We must check the language so the validation
       * will be reset if there are no selected languages
       */
      let found = false;
      this.associated_languages.forEach(l => {
        if (l.id == this.default_language) {
          found = true;
        }
      });

      if (false === found) {
        this.default_language = null;
      }
    },

    //
    loadTypes() {
      if (this.roadTypes.length > 1) {
        return;
      }
      this.loadingTypes = true;

      // Load all road types
      this.$store
        .dispatch('restaurants/getRoadTypes')
        .then(response => {
          this.clearErrors();

          this.roadTypes = response.data;

          if (this.road_type != null) {
            this.road_type = this.roadTypes.find(
              t => t.id === this.road_type.id
            );
          }
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loadingTypes = false;
        });
    },

    loadLanguages() {
      if (this.languagesLoaded) {
        return;
      }

      this.loadingLanguages = true;

      // Load all languages
      this.$store
        .dispatch('languages/load')
        .then(response => {
          this.clearErrors();
          this.languagesLoaded = true;
          this.languages = response.data;
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loadingLanguages = false;
        });
    },
  },
};
</script>
