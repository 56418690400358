<template>
  <v-card
    flat
    tile
    ripple
    :class="last ? '' : 'border-bottom'"
    @click="$emit('click')"
  >
    <v-card-text>
      <v-row no-gutters align="center">
        <v-col cols="3" class="subtitle-2 text-truncate d-none d-sm-flex">
          <v-icon class="pr-2">mdi-account-card-details</v-icon>
          <span class="mr-5">{{ restaurant.nif }}</span>
        </v-col>

        <v-col cols="6" class="text-truncate grey--text text--darken-3">
          <v-icon class="pr-2">mdi-store</v-icon>
          {{ restaurant.name }}
        </v-col>

        <v-col cols="3" class="align-end subtitle-2">{{
          restaurant.phone_number
        }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    last: Boolean,
    restaurant: Object,
  },

  methods: {},
};
</script>

<style></style>
