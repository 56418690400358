<template>
  <v-card flat tile ripple :class="last ? '' : 'border-bottom'">
    <v-card-text>
      <v-row no-gutters align="center">
        <v-col
          cols="6"
          sm="4"
          md="2"
          class="subtitle-2 text-truncate d-sm-flex"
        >
          <v-icon class="pr-2">mdi-package-variant</v-icon>
          <span class="mr-5">{{ pack.alias }}</span>
        </v-col>

        <v-col
          cols="6"
          sm="4"
          md="3"
          class="text-truncate grey--text text--darken-3"
          >{{ generatedOn }}</v-col
        >

        <v-col
          md="3"
          class="justify-center subtitle-2 grey--text text--darken-3 d-none d-md-flex"
          >{{ pack.tables_count }}</v-col
        >

        <v-col cols="2" class="justify-center subtitle-2 d-none d-sm-flex">
          <v-icon class="pr-2" v-if="pack.assigned == 0"
            >mdi-checkbox-blank-outline</v-icon
          >
          <v-icon class="pr-2" v-else color="primary"
            >mdi-checkbox-marked</v-icon
          >
        </v-col>

        <v-col cols="2" class="justify-center subtitle-2 d-none d-sm-flex">
          <v-icon class="pr-2" v-if="pack.printed == 0"
            >mdi-checkbox-blank-outline</v-icon
          >
          <v-icon class="pr-2" v-else>mdi-checkbox-marked</v-icon>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    last: Boolean,
    pack: Object,
  },

  computed: {
    generatedOn() {
      return dayjs(this.pack.created_at).format('YYYY-MM-DD');
    },
  },

  methods: {},
};
</script>

<style></style>
